import React, { useState, useEffect } from 'react';
import { Route, Routes, Navigate, useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Container, Box, Button, Typography, Alert, Modal } from '@mui/material';
import { styled } from '@mui/system';
import Confetti from 'react-confetti';
import UserForm from './components/UserForm';
import AdminDashboard from './components/AdminDashboard';
import Login from './components/Login';
import SpinningWheel from './components/SpinningWheel';
import NavBar from './components/NavBar';
import Contenders from "./components/Admin/Contenders";
import WinnerList from './components/Admin/List';
import ListAll from './components/Admin/ListAll';
import TermsConditions from './components/TermsConditions';
import Luaj from './titulli.png';
import Map from './components/Admin/Map';
import OutOfService from './components/OutOfService'; 

const RootContainer = styled(Container)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  minHeight: '100vh',
  width: '100vw',
  padding: theme.spacing(3),
}));

const PrizeModalBox = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  backgroundColor: 'white',
  boxShadow: 24,
  padding: theme.spacing(4),
  outline: 'none',
}));

function App() {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phone: '',
    address: '',
    code: '',
    termsAccepted: false,
  });
  const [result, setResult] = useState('');
  const [showWheel, setShowWheel] = useState(false);
  const [prize, setPrize] = useState('');
  const [spinPrize, setSpinPrize] = useState('');
  const [showConfetti, setShowConfetti] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [showWinnerMessage, setShowWinnerMessage] = useState(false);
  const [isAuthenticated, setAuth] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [showPlayAgainButton, setShowPlayAgainButton] = useState(false);
  const [isOutOfService, setIsOutOfService] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setAuth(true);
    }
  }, []);

  useEffect(() => {
    const checkAuth = async () => {
      const token = localStorage.getItem('token');
      if (token) {
        try {
          const response = await axios.get('/api/auth/check', {
            headers: {
              'x-auth-token': token
            }
          });
          if (response.data.valid) {
            setAuth(true);
            if (location.pathname === '/login') {
              navigate('/admin');
            }
          } else {
            setAuth(false);
            if (location.pathname === '/admin') {
              navigate('/login');
            }
          }
        } catch (error) {
          setAuth(false);
          if (location.pathname === '/admin') {
            navigate('/login');
          }
        }
      } else {
        setAuth(false);
        if (location.pathname === '/admin') {
          navigate('/login');
        }
      }
    };

    checkAuth();
  }, [location.pathname, navigate]);

  const handleLogout = () => {
    localStorage.removeItem('token');
    setAuth(false);
    navigate('/login');
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === 'checkbox' ? checked : value,
    });
  };

  const validateFormData = () => {
    if (!formData.fullName || !formData.email || !formData.phone || !formData.address || !formData.code || !formData.termsAccepted) {
      return false;
    }
    return true;
  };

  const handleSubmit = async (formData) => {
    if (!validateFormData()) {
      setResult('Të gjitha fushat janë të detyrueshme.');
      return;
    }

    try {
      const response = await axios.post('/api/userEntries/submit', formData);

      if (response.data.message === 'Invalid code') {
        setResult('Ky kod nuk ekziston');
      } else if (response.data.message === 'Code has already been used') {
        setResult('Ky kod nuk mund të përdoret më shumë se një herë.');
      } else {
        setPrize(response.data.prize || 'Provoni përsëri');
        setResult(response.data.message);
        setShowForm(false);
        setShowWinnerMessage(true);

        setTimeout(() => {
          setShowWinnerMessage(false);
          setShowWheel(true); 
        }, 1000);
      }
    } catch (error) {
      if (error.response && error.response.data && error.response.data.message) {
        setResult(error.response.data.message);
      } else {
        setResult('Gabim në përpunimin e kërkesës. Ju lutem provoni përsëri.');
      }
      setShowWheel(false);
    }
  };

  const handleSpinFinish = () => {
    setSpinPrize(prize);

    if (prize !== 'Provoni përsëri') {
      setShowConfetti(true);
      setTimeout(() => {
        setModalOpen(true);
        setShowConfetti(false);
        setShowPlayAgainButton(true);
      }, 3000);
    } else {
      setShowPlayAgainButton(true);
    }
  };

  const handleCloseModal = () => {
    setModalOpen(false);
    window.location.href = '/';  // This will reload the page and reset everything
  };

  const resetGame = () => {
    setFormData({
      fullName: '',
      email: '',
      phone: '',
      address: '',
      code: '',
      termsAccepted: false,
    });
    setResult('');
    setShowWheel(false);
    setPrize('');
    setSpinPrize('');
    setShowForm(true);
    setShowConfetti(false);
    setModalOpen(false);
    setShowPlayAgainButton(false);
  };

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const response = await axios.get('/api/maintenance-status');
        setIsOutOfService(response.data.maintenanceMode);
      } catch (error) {
        console.error('Failed to fetch maintenance status:', error);
      }
    };

    fetchMaintenanceStatus();
  }, []);

  const toggleMaintenanceMode = async () => {
    try {
      const response = await axios.post('/api/toggle-maintenance');
      setIsOutOfService(response.data.maintenanceMode);
    } catch (error) {
      console.error('Failed to toggle maintenance mode:', error);
    }
  };


  return (
    <div className="background-container">
      <div className="bg"></div>
      {showConfetti && <Confetti />}
      <NavBar
        auth={isAuthenticated}
        handleLogout={handleLogout}
        toggleMaintenanceMode={toggleMaintenanceMode}
        isMaintenanceMode={isOutOfService}
      />
      <RootContainer className="content">
        <Routes>
          {isOutOfService && location.pathname === '/' ? (
            <Route path="*" element={<OutOfService />} />
          ) : (
            <>
              <Route path="/" element={
                <section className='align-center h-custom c-gap'>
                {/* Conditionally show the logo only when the form is visible */}
                {showForm && (
                  <img src={Luaj} alt="Logo" className='logo-form' />
                )}
              
                {/* Conditionally show the form or the spinning wheel */}
                {showForm ? (
                  <>
                    <UserForm formData={formData} handleChange={handleChange} handleSubmit={handleSubmit} />
                    {result && (
                      <Alert severity={result.includes('Congratulations') ? 'success' : 'error'}>
                        {result}
                      </Alert>
                    )}
                  </>
                ) : (
                  showWheel && (
                    <SpinningWheel prize={prize} onSpinFinish={handleSpinFinish} resetGame={resetGame} />
                  )
                )}
              </section>
              } />
              <Route path="/login" element={<Login setAuth={setAuth} />} />
              <Route path="/admin" element={isAuthenticated ? <AdminDashboard toggleMaintenanceMode={toggleMaintenanceMode} isMaintenanceMode={isOutOfService} /> : <Navigate to="/login" />} />
              <Route path="/contenders" element={isAuthenticated ? <Contenders /> : <Navigate to="/login" />} />
              <Route path="/winners" element={isAuthenticated ? <WinnerList /> : <Navigate to="/login" />} />
              <Route path="/all-contenders" element={isAuthenticated ? <ListAll /> : <Navigate to="/login" />} />
              <Route path="/terms-conditions" element={<TermsConditions />} />
              <Route path="/map" element={isAuthenticated ? <Map /> : <Navigate to="/login" />} />
            </>
          )}
        </Routes>
        <Modal
          open={modalOpen}
          onClose={handleCloseModal}
          aria-labelledby="prize-modal-title"
          aria-describedby="prize-modal-description"
        >
          <PrizeModalBox className='modal-win'>
            <Typography id="prize-modal-title" variant="h6" component="h2">
              Urime!
            </Typography>
            <Typography id="prize-modal-description" sx={{ mt: 2 }}>
              Ju keni fituar: {spinPrize}
            </Typography>
            <Typography>
            Për të marrë shpërblimin tuaj, vizitoni zyrën tonë me amballazhin e produktit që përmban kodin fitues.
              <br /><br />
              Adresa e zyrës sonë: ESSI Group SHPK. Sllatinë, Kosovë
            </Typography>
            <Box mt={3} textAlign="center">
              <Button variant="contained" color="primary" onClick={handleCloseModal}>
                Mbylleni
              </Button>
            </Box>
          </PrizeModalBox>
        </Modal>
      </RootContainer>
    </div>
  );
}

export default App;