import React from 'react';
import Medal from "../../svg/medal.svg";
import { useNavigate } from 'react-router-dom';
import Arrow from "./back-arrow.svg"

function Cards() {
 /*  const [winners, setWinners] = useState(0);
  const [losers, setLosers] = useState(0);
  const [contendersCount, setContendersCount] = useState(0); // State to hold the number of contenders
 */
 /*  useEffect(() => {
    const fetchCounts = async () => {
      try {
        const contendersResponse = await fetch('/api/admin/contenders');
        console.log('Contenders Response:', contendersResponse); // Add logging
        if (!contendersResponse.ok) {
          throw new Error(`Error fetching contenders: ${contendersResponse.statusText}`);
        }
        const contendersData = await contendersResponse.json();
        console.log('Contenders Data:', contendersData); // Log the data
        setContendersCount(contendersData.length); // Store the count of contenders
  
        const winnersResponse = await fetch('/winners-count');
        console.log('Winners Response:', winnersResponse); // Add logging
        if (!winnersResponse.ok) {
          throw new Error(`Error fetching winners: ${winnersResponse.statusText}`);
        }
        const winnersData = await winnersResponse.json();
        console.log('Winners Data:', winnersData); // Log the data
        setWinners(winnersData.winners);
  
        const losersResponse = await fetch('/losers-count');
        console.log('Losers Response:', losersResponse); // Add logging
        if (!losersResponse.ok) {
          throw new Error(`Error fetching losers: ${losersResponse.statusText}`);
        }
        const losersData = await losersResponse.json();
        console.log('Losers Data:', losersData); // Log the data
        setLosers(losersData.losers);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchCounts();
  }, []); */

  const navigate = useNavigate();

  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };

  return (
    <section className='cards-row'>
      <div className='flex-start mobile-device' onClick={handleBack} style={{ cursor: 'pointer' }}>
      <img src={Arrow} alt="Back" className='back-arrow' />
    </div>
      <div className='card card1'>
        <img src={Medal} alt="Medal" />
        <h2>Winners</h2>
        <button className='btn' onClick={() => navigate('/winners')}>Show</button>
      </div>
      <div className='card card2'>
        <img src={Medal} alt="Medal" />
        <h2>Non-Winners</h2>
        <button className='btn' onClick={() => navigate('/contenders')}>Show</button>
      </div>
      <div className='card card3'>
        <img src={Medal} alt="Medal" />
        <h2>Contenders</h2> {/* Display the number of contenders */}
        <button className='btn' onClick={() => navigate('/all-contenders')}>Show</button>
      </div>
    </section>
  );
}

export default Cards;