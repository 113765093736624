import React, { useState, useEffect } from 'react';
import { TextField, Button, Box, Checkbox, FormControlLabel, Link, Typography, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { styled } from '@mui/system';

const FormBox = styled(Box)(({ theme }) => ({
  width: '100%',
  maxWidth: '400px',
  backgroundColor: '#fff',
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const SubmitButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

function UserForm({ formData, handleChange, handleSubmit }) {
  const [recaptchaToken, setRecaptchaToken] = useState(null);
  const [emailSubmitCount, setEmailSubmitCount] = useState(0);
  const [firstSubmitTime, setFirstSubmitTime] = useState(null);
  const [error, setError] = useState("");
  const [lastSubmittedEmail, setLastSubmittedEmail] = useState("");

  useEffect(() => {
    loadRecaptchaScript();
  }, []);

  const loadRecaptchaScript = () => {
    const script = document.createElement('script');
    script.src = `https://www.google.com/recaptcha/api.js?render=6LdR6ykqAAAAAK6IUxzKxlBSP2VfzFFr2rIHe0wP`;
    script.async = true;
    script.defer = true;
    script.onload = () => {};
    script.onerror = () => {};
    document.body.appendChild(script);
  };

  // Function to format phone number as XXX-XXX-XXX
  const formatPhoneNumber = (value) => {
    const cleaned = value.replace(/\D/g, '').slice(0, 9); // Remove non-digits and limit to 9 digits
    const match = cleaned.match(/^(\d{0,3})(\d{0,3})(\d{0,3})$/);
  
    if (match) {
      const formattedPhone = [match[1], match[2], match[3]].filter(Boolean).join('-');
      return formattedPhone;
    }
    return value;
  };

  const handlePhoneChange = (e) => {
    const { value } = e.target;
    const formattedPhone = formatPhoneNumber(value);
    handleChange({ target: { name: 'phone', value: formattedPhone } });
  };

  const executeRecaptcha = (e) => {
    e.preventDefault();

    const currentTime = Date.now();
    if (
      formData.email === lastSubmittedEmail &&
      emailSubmitCount >= 4 &&
      firstSubmitTime &&
      (currentTime - firstSubmitTime) < 60000
    ) {
      setError("Ju mund të përdorni të njejtin email vetëm 4 herë brenda 1 minuti.");
      return;
    }

    if (window.grecaptcha) {
      window.grecaptcha.ready(() => {
        window.grecaptcha.execute('6LdR6ykqAAAAAK6IUxzKxlBSP2VfzFFr2rIHe0wP', { action: 'submit' }).then((token) => {
          setRecaptchaToken(token);
          handleFormSubmit(e, token);
        });
      });
    }
  };

  const handleFormSubmit = async (e, token) => {
    e.preventDefault();

    if (!token) {
      setError("Please complete reCAPTCHA.");
      return;
    }

    const currentTime = Date.now();
    if (formData.email !== lastSubmittedEmail) {
      setEmailSubmitCount(0);
      setFirstSubmitTime(currentTime);
    }

    if (firstSubmitTime && (currentTime - firstSubmitTime) >= 60000) {
      setEmailSubmitCount(0);
      setFirstSubmitTime(currentTime);
    }

    setEmailSubmitCount(prevCount => prevCount + 1);
    setLastSubmittedEmail(formData.email);

    const formDataWithRecaptcha = { ...formData, recaptchaToken: token };
    handleSubmit(formDataWithRecaptcha);

    setError("");
  };

  return (
    <FormBox className='form-c'>
      <form onSubmit={executeRecaptcha}>
        <TextField
          variant="outlined"
          fullWidth
          label="Emri"
          name="fullName"
          value={formData.fullName}
          onChange={handleChange}
          required
          margin="normal"
        />
        <TextField
          variant="outlined"
          fullWidth
          label="Email"
          name="email"
          type="email"
          value={formData.email}
          onChange={handleChange}
          required
          margin="normal"
        />
        <TextField
          variant="outlined"
          fullWidth
          label="Numri i Telefonit"
          name="phone"
          value={formData.phone}
          onChange={handlePhoneChange}  // Use the new phone change handler
          required
          margin="normal"
        />
        <FormControl fullWidth variant="outlined" margin="normal" required>
          <InputLabel id="komuna-label" >Komuna</InputLabel>
          <Select
            labelId="komuna-label"
            label="Komuna"
            name="address"
            value={formData.address}
            onChange={handleChange}
            required
          >
          <MenuItem value="Artanë">Artanë</MenuItem>
<MenuItem value="Besianë">Besianë</MenuItem>
<MenuItem value="Burim">Burim</MenuItem>
<MenuItem value="Dardanë">Dardanë</MenuItem>
<MenuItem value="Deçan">Deçan</MenuItem>
<MenuItem value="Dragash">Dragash</MenuItem>
<MenuItem value="Drenas">Drenas</MenuItem>
<MenuItem value="Ferizaj">Ferizaj</MenuItem>
<MenuItem value="Fushë Kosovë">Fushë Kosovë</MenuItem>
<MenuItem value="Gjakovë">Gjakovë</MenuItem>
<MenuItem value="Gjilan">Gjilan</MenuItem>
<MenuItem value="Kastriot">Kastriot</MenuItem>
<MenuItem value="Kaçanik">Kaçanik</MenuItem>
<MenuItem value="Klinë">Klinë</MenuItem>
<MenuItem value="Leposaviq">Leposaviq</MenuItem>
<MenuItem value="Lipjan">Lipjan</MenuItem>
<MenuItem value="Malishevë">Malishevë</MenuItem>
<MenuItem value="Mitrovicë">Mitrovicë</MenuItem>
<MenuItem value="Pejë">Pejë</MenuItem>
<MenuItem value="Prishtinë">Prishtinë</MenuItem>
<MenuItem value="Prizren">Prizren</MenuItem>
<MenuItem value="Podujeva">Podujeva</MenuItem>
<MenuItem value="Kamenica">Kamenica</MenuItem>
<MenuItem value="Rahovec">Rahovec</MenuItem>
<MenuItem value="Skenderaj">Skenderaj</MenuItem>
<MenuItem value="Shtërpcë">Shtërpcë</MenuItem>
<MenuItem value="Shtime">Shtime</MenuItem>
<MenuItem value="Therandë">Therandë</MenuItem>
<MenuItem value="Viti">Viti</MenuItem>
<MenuItem value="Vushtrri">Vushtrri</MenuItem>
<MenuItem value="Zubin Potok">Zubin Potok</MenuItem>
<MenuItem value="Zveçan">Zveçan</MenuItem>
          </Select>
        </FormControl>
        <TextField
          variant="outlined"
          fullWidth
          label="Kodi"
          name="code"
          value={formData.code}
          onChange={handleChange}
          required
          margin="normal"
        />
        <div className='f-left'>
          <FormControlLabel
            control={
              <Checkbox
                name="termsAccepted"
                checked={formData.termsAccepted}
                onChange={handleChange}
                required
              />
            }
            label={
              <span className='black-text'>
                Unë pranoj{' '}
                <Link href="/terms-conditions" >
                  Termat & Kushtet
                </Link>
              </span>
            }
          />
        </div>
        {error && (
          <Typography color="error" variant="body2" style={{ marginTop: '10px' }}>
              Ju lutem plotësoni të gjitha fushat e kërkuara.
          </Typography>
        )}
        <SubmitButton
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={!formData.termsAccepted}
        >
          Submit
        </SubmitButton>
      </form>
    </FormBox>
  );
}

export default UserForm;