import React, { useEffect, useState } from 'react';
import Cards from './Admin/Cards';
import Map from './Admin/Map';

const AdminDashboard = (/* { toggleMaintenanceMode, isMaintenanceMode } */) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    
    // Check screen size on initial load
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
        {isSmallScreen ? (
          <p className='notif'>Për të pasur akses brenda panelit të administratorit, duhet të përdorni një desktop me një ekran më të madh.</p>
        ) : (
          <section className='admin-wrapper'>
            {/* <button onClick={toggleMaintenanceMode} className='btn'>
              {isMaintenanceMode ? 'Disable Maintenance Mode' : 'Enable Maintenance Mode'}
            </button> */}
            <Map />
            <Cards />
      </section>
        )}
    </>
  );
};

export default AdminDashboard;
