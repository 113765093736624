import React from 'react';

const OutOfService = () => {
  return (
    <div className="out-of-service-container">
      <div className="out-of-service-message">
        <h1>Faqja është në Mirëmbajtje</h1>
        <p>Po bëjmë përmirësime për të përmirësuar përvojën tuaj. Faqja do të jetë sërish online së shpejti. Ju faleminderit për mirëkuptimin.</p>
      </div>
    </div>
  );
};

export default OutOfService;
