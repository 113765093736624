import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { AppBar, Toolbar, Typography, Button } from '@mui/material';
import { styled } from '@mui/system';
import logo from "../logo.svg";

const StyledLink = styled(Link)(({ theme }) => ({
  color: 'inherit',
  textDecoration: 'none',
  margin: theme.spacing(1),
}));

const NavBar = ({ auth, handleLogout, isMaintenanceMode, toggleMaintenanceMode }) => {
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1024);
    };

    window.addEventListener('resize', handleResize);
    
    // Check screen size on initial load
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <AppBar className='p-custom'>
      <Toolbar>
        <Typography variant="h6">
          <a href='/'>
            <img src={logo} className='logo' alt="Logo" />
          </a>
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          {auth ? (
            <div className='flex-end gap-20'>
              {/* Show maintenance mode button only for admin and large screens */}
                <Button color="inherit" className='btn-white mobile-device' onClick={toggleMaintenanceMode} disableTouchRipple>
                  {isMaintenanceMode ? 'Maintenance: On' : 'Maintenance: Off'}
                </Button>
              <Button color="inherit" className='btn-logout' onClick={handleLogout}>Shkyç</Button>
            </div>
          ) : null}
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default NavBar;
