import React, { useState, useEffect } from 'react';
import { Wheel } from 'react-custom-roulette';
import { Box, Typography, Button, Modal } from '@mui/material';
import { styled } from '@mui/system';
import keniFituar from "./keni-fituar.svg";
import tryAgainImage from "./TryAgain.svg";

const prizes = [
  { image: '/images/tryagain.png', name: 'Provoni përsëri' },
  { image: '/images/biciklete.png', name: 'Biçikletë' },
  { image: '/images/tryagain.png', name: 'Provoni përsëri' },
  { image: '/images/skuter.png', name: 'Skuter elektrik' },
  { image: '/images/tryagain.png', name: 'Provoni përsëri' },
  { image: '/images/enelarese.png', name: 'Enëlarëse' },
  { image: '/images/tryagain.png', name: 'Provoni përsëri' },
  { image: '/images/set-tenxheresh.png', name: 'Set Tenxheresh' },
  { image: '/images/tryagain.png', name: 'Provoni përsëri' },
  { image: '/images/set-enesh.png', name: 'Set Enësh' },
  { image: '/images/tryagain.png', name: 'Provoni përsëri' },
  { image: '/images/iphone.png', name: 'Iphone 16' },
  { image: '/images/tryagain.png', name: 'Provoni përsëri' },
  { image: '/images/set-tenxheresh.png', name: 'Set Tenxheresh' },
];

const getColorByIndex = (index) => {
  const colors = [
    { background: '#FACC3F', text: '#000000' },
    { background: '#F33838', text: '#FFFFFF' },
    { background: '#FACC3F', text: '#000000' },
    { background: '#F33838', text: '#FFFFFF' },
    { background: '#FACC3F', text: '#000000' },
    { background: '#F33838', text: '#FFFFFF' },
    { background: '#FACC3F', text: '#000000' },
    { background: '#F33838', text: '#FFFFFF' },
  ];
  return colors[index % colors.length];
};

const data = prizes.map((prize, index) => {
  const { background, text } = getColorByIndex(index);
  return {
    option: prize.name,
    style: {
      backgroundColor: background,
      textColor: text
    }
  };
});

const ZoomedPrizeContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  borderRadius: '50%',
  transition: 'transform 0.5s ease-in-out',
  transform: 'scale(1.5)',
}));

const SpinningWheel = ({ prize, onSpinFinish, resetGame }) => {
  const [mustSpin, setMustSpin] = useState(false);
  const [prizeIndex, setPrizeIndex] = useState(null);
  const [zoomPrize, setZoomPrize] = useState(null);
  const [openModal, setOpenModal] = useState(false);

  useEffect(() => {
    if (prize) {
      const prizeIdx = prizes.findIndex(item => item.name.toLowerCase() === prize.toLowerCase());
      if (prizeIdx !== -1) {
        setPrizeIndex(prizeIdx);
        setMustSpin(true);
      } else {
        console.error("Prize not found:", prize);
      }
    }
  }, [prize]);

  const handlePrizeDefined = () => {
    if (prizeIndex === null) {
      console.error("No prize index found");
      return;
    }
    setMustSpin(false);
    const wonPrize = prizes[prizeIndex];
    setZoomPrize(wonPrize);
    if (wonPrize.name === 'Provoni përsëri') {
      setOpenModal(true);
    }
    if (onSpinFinish) {
      onSpinFinish();
    }
  };

  const handleCloseAndReset = () => {
    // Force a page reload by setting window.location.href to the homepage
    window.location.href = '/';  // This will reload the page and reset everything
  };

  return (
    <Box textAlign="center" id='sp-wheel'>
      {zoomPrize ? (
        <div className='align-center'>
          <img src={zoomPrize.name === 'Provoni përsëri' ? tryAgainImage : keniFituar} alt="Prize" className='prize-svg' />
          <ZoomedPrizeContainer className='zoomed-prize' style={{ backgroundImage: `url(${zoomPrize.image})`, height: '400px', width: '400px' }} />
        </div>
      ) : (
        <section className='h-custom align-center' style={{ transform: 'scale(1.2)' }}>
         <Wheel
            mustStartSpinning={mustSpin}
            prizeNumber={prizeIndex}
            data={data}
            onStopSpinning={handlePrizeDefined}
            backgroundColors={['#fafafa', '#fafafa']}
            textColors={['#fafafa']}
            outerBorderWidth={1}
            outerBorderColor="#FACC3F"
            radiusLineWidth={0.3}
            radiusLineColor='#fafafa'
            spinDuration={1}
         />
        </section>
      )}

      <Modal
        open={openModal}
        onClose={handleCloseAndReset}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className='modal-prize'
      >
        <Box
          id='fs'
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: 600,
            bgcolor: 'var(--Gray-White, #FFF)',
            border: 'none',
            borderRadius: '8px',
            boxShadow: '0px 0px 2px 0px rgba(0, 0, 0, 0.12), 0px 20px 20px 0px rgba(0, 0, 0, 0.08)',
            p: 4,
          }}
        >
          <Typography id="modal-title" variant="h6" component="h2">
            Provoni përsëri
          </Typography>
          <Typography id="modal-description" sx={{ mt: 2 }}>
          Faleminderit që morët pjesë në lojën tonë të shpërblimeve! Edhe pse nuk fituat këtë herë, jemi të kënaqur t'ju njoftojmë se ende jeni në lojë për shpërblimin Kryesor - një Veturë e re Renault Clio me KM 00
          </Typography>
          <div className='flex-end'>
            <Button onClick={handleCloseAndReset} sx={{ mt: 2 }} variant="contained">
              Mbylleni
            </Button>
          </div>
        </Box>
      </Modal>
    </Box>
  );
};

export default SpinningWheel;
